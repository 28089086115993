import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';
import Sidebar2024 from '../Humanity2023/Sidebar2024';


const SocialStudiesCCA3to52024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/Humanity/SocialStudiesCCA3to5/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/Humanity/SocialStudiesCCA3to5/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/Humanity/SocialStudiesCCA3to5/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/Humanity/SocialStudiesCCA3to5/4.webp`;



    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 2,
        },

    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h5' align='center' >
                                    SOCIAL STUDIES CCA 3 TO 5
                                </Typography>
                                <br></br>

                                <Typography variant='h7' align='justify' >
                                    Class 3: Festival Calendar: Evolution and importance of festivals <br />
                                    Class 4: Art Forms: History and Evolution <br />
                                    Class 5: Environmental Tourism: Itinerary Creation
                                    <br />
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 3 to 5       Date:26 November 2024
                                </Typography>
                                {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Dependence on nature will lead to harmony and peace in society and will not cause depravity of individuals’”-Rousseau

<br/>
        </Typography> */}
                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Social Studies CCA, held during the third and fourth weeks of November, saw active and enthusiastic participation from students of Classes 3 to 5.
                                    <br></br>
                                    Class 3 students confidently presented a festival calendar for a specific month, highlighting key festivals and demonstrating both their knowledge and public speaking abilities.
                                    <br></br>
                                    Class 4 students displayed their understanding of the history and evolution of various art forms, such as Warli, Palampore and Kalamkari, by taking help of unique greeting cards done in the preliminary round.
                                    <br></br>
                                    Class 5 students organised an engaging presentation by designing itineraries for environmental destinations selected during the preliminary rounds. These itineraries included details on transportation, accommodation, dining options and nearby scenic attractions.
                                    <br></br>
                                    This activity fostered collaborative learning, deepened students' understanding of different cultural and environmental topics and sparked their creativity. The varied approaches taken by each group turned the event into a dynamic celebration of talent and innovation.
                                    <br></br>
                                </Typography>
                            </Box>
                            {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       “Constitution is not a mere lawyers document, it is a vehicle of life and its spirit is always the spirit of age” 

<br/>
        </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>

                        <Box marginBottom={4}>
                            <Sidebar2022 />
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default SocialStudiesCCA3to52024;